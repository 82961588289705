<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="showCreateClusterModal()">
        <PlusOutlined />新增
      </a-button>
    </div>
    <div class="table-search">
      <a-form :model="formState" layout="inline" autocomplete="off" @submit="handleRefresh">
        <a-form-item label="群体名称">
          <a-input v-model:value="formState.search_keys" placeholder="请输入群体名称" />
        </a-form-item>
        <a-form-item class="search-btn">
          <a-button type="primary" html-type="submit">搜索</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-table rowKey="cluster_id" :columns="columns" :data-source="data" :pagination="Refpagination" @change="handleRefresh">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'action'">
          <span>
            <a @click="showUpdateClusterModal(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="showDeleteConfirm(record)">删除</a>
          </span>
        </template>
      </template>
    </a-table>
    <create-Cluster ref="RefCreateCluster" :labelList="RefLabelList" :levelList="RefLevelList" @handleSubmit="handleRefresh" />
    <update-cluster ref="RefUpdateCluster" :labelList="RefLabelList" :levelList="RefLevelList" @handleSubmit="handleRefresh" />
  </div>
</template>
<script>
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode, defineComponent, reactive, ref, onMounted } from "vue";
import { Modal, message } from "ant-design-vue";
import CreateCluster from "./modules/cluster/CreateCluster.vue";
import UpdateCluster from "./modules/cluster/UpdateCluster.vue";
import * as Api from "@/views/user/api";
const columns = [
  {
    title: "ID",
    dataIndex: "cluster_id",
    width: "10%",
  },
  {
    title: "群体名称",
    dataIndex: "cluster_name",
    width: "10%",
  },
  {
    title: "更新时间",
    dataIndex: "update_time",
    width: "16%",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "20%",
  },
];

export default defineComponent({
  components: {
    PlusOutlined,
    CreateCluster,
    UpdateCluster,
  },
  setup() {
    const formState = reactive({
      search_keys: "",
    });

    const data = ref([]);
    const Refpagination = ref({});

    // 添加群体
    const RefCreateCluster = ref();
    const showCreateClusterModal = () => {
      RefCreateCluster.value.showFromModal();
    };

    // 编辑群体
    const RefUpdateCluster = ref();
    const showUpdateClusterModal = (item) => {
      RefUpdateCluster.value.showFromModal(item);
    };

    // 刷新用户列表数据
    const handleRefresh = (pagination) => {
      if (typeof pagination !== "undefined") {
        formState.page = pagination.current;
      }
      Api.user_cluster_list(formState).then((res) => {
        Refpagination.value.current = res.data.list.current_page;
        Refpagination.value.pageSize = res.data.list.per_page;
        Refpagination.value.total = res.data.list.total;
        data.value = res.data.list.data;
      });
    };

    // 等级列表
    const RefLevelList = ref();

    // 刷新等级列表数据
    const getLevelList = () => {
      Api.user_level_all().then((res) => {
        RefLevelList.value = res.data.list;
      });
    };

    // 标签列表
    const RefLabelList = ref();

    // 刷新角色列表数据
    const getLabelList = () => {
      Api.user_label_all().then((res) => {
        RefLabelList.value = res.data.list;
      });
    };

    const showDeleteConfirm = (record) => {
      Modal.confirm({
        title: "确定要删除【" + record.cluster_name + "】吗?",
        icon: createVNode(ExclamationCircleOutlined),
        content: "删除用户后将无法恢复，请谨慎操作！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          Api.user_del({ id: record.uid }).then((res) => {
            message.success(res.message);
            handleRefresh();
          });
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    };

    onMounted(() => {
      handleRefresh();
      getLabelList();
      getLevelList();
    });

    return {
      value1: ref(),
      formState,
      columns,
      data,
      Refpagination,
      RefCreateCluster,
      RefUpdateCluster,
      showCreateClusterModal,
      showUpdateClusterModal,
      showDeleteConfirm,
      handleRefresh,
      RefLabelList,
      RefLevelList,
    };
  },
});
</script>